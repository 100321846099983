import React, { useState, useRef } from "react";
import "./login.css";
import {
	getAuth,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
} from "firebase/auth";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";

function Login() {
	const [user, setUser] = useState("", "");
	const auth = getAuth();
	const HP = useRef();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUser({ ...user, [name]: value });
	};

	onAuthStateChanged(auth, (user) => {
		if (user) {
			// User is signed in, see docs for a list of available properties
			// https://firebase.google.com/docs/reference/js/auth.user
			const uid = user.uid;
		} else {
			// User is signed out
		}
	});

	function login(e) {
		e.preventDefault();

		if (HP.current.value) {
			return; // Ne rien faire et retourner si le champ honeypot est rempli
		}
		signInWithEmailAndPassword(auth, user.username, user.password)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;
				toast.success("Utilisateur connecté", {
					position: "bottom-center",
				});
			})
			.catch((error) => {
				toast.error("Erreur : Mot de passe incorrect", {
					position: "bottom-center",
				});
				console.error(error);
			});
	}
	return (
		<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
			<Helmet>
				<title>Login - Jeunesse de Savièse</title>
				<meta name="description" content="Login pour la gestion du site" />
				<meta name="robots" content="noindex, nofollow" />{" "}
				{/* Empêche l'indexation par les moteurs de recherche */}
			</Helmet>
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<h2 className="md:text-4xl text-4xl md:leading-10 font-extrabold text-[#333] mb-4 text-center">
					Page de login
				</h2>
			</div>

			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
				<form className="space-y-6" onSubmit={login}>
					<div>
						<label
							htmlFor="email"
							className="block  font-medium leading-6 text-gray-900">
							Adresse e-mail
						</label>
						<div className="mt-2">
							<input
								id="username"
								name="username"
								type="username"
								autoComplete="username"
								onChange={handleChange}
								required
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm: sm:leading-6"
							/>
						</div>
					</div>

					<div>
						<div className="flex items-center justify-between">
							<label
								htmlFor="password"
								className="block  font-medium leading-6 text-gray-900">
								Mot de passe
							</label>
						</div>
						<div className="mt-2">
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="current-password"
								onChange={handleChange}
								required
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm: sm:leading-6"
							/>
						</div>
						<input
							type="text"
							name="hp"
							ref={HP}
							style={{
								position: "absolute",
								left: "-9999px",
								top: "auto",
								width: "1px",
								height: "1px",
								overflow: "hidden",
							}}
							tabIndex="-1"
							autoComplete="off"
						/>
					</div>

					<div>
						<button
							type="submit"
							className="flex w-full justify-center px-6 py-3 mb-8  rounded text-white text-lg tracking-wider font-medium outline-none border-2 border-[#ff6348] bg-[#ff6348] hover:bg-transparent hover:text-black transition-all duration-300  ">
							Se connecter
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}
const Logout = () => {
	const auth = getAuth();

	signOut(auth)
		.then(() => {
			toast.success("Utilisateur déconnecté", {
				position: "bottom-center",
			});
		})
		.catch((error) => {
			console.error(error);
			toast.error("Impossible de déconnecter l'utilisateur", {
				position: "bottom-center",
			});
			// An error happened.
		});
};

export { Login, Logout };
