import { Helmet } from "react-helmet";
import emailjs from "@emailjs/browser";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";

function ContactPage() {
  const [loading, setLoading] = useState(false);

  const Nom = useRef();
  const Email = useRef();
  const Sujet = useRef();
  const Message = useRef();
  const HP = useRef();

  useEffect(() => emailjs.init("u3LK1Gyh_Pp3J2-In"), []);

  async function onSubmitFinal(e) {
    console.log("Ok");
    e.preventDefault();
    if (HP.current.value !== "") {
      console.log("bot");
      // C'est un bot, ignorer la soumission
      return;
    }
    try {
      const serviceId = "service_yx7jq4n";
      const templateId = "template_fz2wutg";
      const emailData = {
        nom: Nom.current.value,
        email: Email.current.value,
        sujet: Sujet.current.value,
        message: Message.current.value,
      };

      // Envoi de l'email
      await emailjs.send(serviceId, templateId, emailData);
      toast.success("Message envoyé", {
        position: "bottom-center",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="animate-fade-up  px-6 my-6 mx-auto max-w-xl bg-white font-[sans-serif]">
      <Helmet>
        <title>Contact - Jeunesse de Savièse</title>
        <meta
          name="description"
          content="Page pour contacter la jeunesse de Savièse"
        />
        <meta
          name="keywords"
          content="jeunesse, Savièse, Valais, planning, activité, événement, contact, inscription"
        />
      </Helmet>
      <h1 className="text-3xl text-[#333] font-extrabold text-center">
        Formulaire de contact
      </h1>
      <form className="mt-8 space-y-4" onSubmit={onSubmitFinal}>
        <input
          type="text"
          placeholder="Nom / Prénom"
          className="w-full rounded-md py-3 px-4 bg-gray-100  outline-blue-500 required"
          ref={Nom}
          required
        />
        <input
          type="email"
          placeholder="Email"
          className="w-full rounded-md py-3 px-4 bg-gray-100  outline-blue-500"
          ref={Email}
          required
        />
        <input
          type="text"
          placeholder="Sujet"
          className="w-full rounded-md py-3 px-4 bg-gray-100  outline-blue-500"
          ref={Sujet}
          required
        />
        <input
          type="text"
          name="hp"
          ref={HP}
          style={{
            position: "absolute",
            left: "-9999px",
            top: "auto",
            width: "1px",
            height: "1px",
            overflow: "hidden",
          }}
          tabIndex="-1"
          autoComplete="off"
        />

        <textarea
          placeholder="Message"
          rows="6"
          className="w-full rounded-md px-4 bg-gray-100  pt-3 outline-blue-500"
          ref={Message}
          required
        ></textarea>
        <button
          type="submit"
          className="px-6 py-3 mb-8  rounded text-white text-lg tracking-wider font-medium outline-none border-2 border-[#ff6348] bg-[#ff6348] hover:bg-transparent hover:text-black transition-all duration-300  "
        >
          Envoyer
        </button>
      </form>
    </div>
  );
}

export default ContactPage;
