import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function ErrorPage() {
	return (
		<>
			<section className="flex items-center  p-16 ">
				<Helmet>
					<title>Erreur 404 - Jeunesse de Savièse</title>
					<meta
						name="description"
						content="Désolé, la page que vous cherchez est introuvable."
					/>
					<meta name="robots" content="noindex, nofollow" />
				</Helmet>
				<div className="container flex flex-col items-center ">
					<div className="flex flex-col gap-6 max-w-md text-center">
						<h2 className="font-extrabold text-9xl text-gray-600 dark:text-gray-100">
							<span className="sr-only">Error</span>404
						</h2>
						<p className="text-2xl md:text-3xl dark:text-gray-300">
							Page introuvable
						</p>
						<Link
							to="/"
							className="px-8 py-4 text-xl font-semibold rounded bg-purple-600 text-gray-50 hover:text-gray-200">
							Retour
						</Link>
					</div>
				</div>
			</section>
		</>
	);
}
export default ErrorPage;
