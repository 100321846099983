import "./App.css";
import Navbar from "./Components/Navbar/navBar";
import AppRoutes from "./services/routes";
import toast, { Toaster } from "react-hot-toast";

function App() {
  return (
    <div>
      <Navbar></Navbar>
      <Toaster></Toaster>
      <div className="animate-fade-up  animate-ease-in-out bg-white pt-18 sm:pt-2  pb-8 md:pb-0">
        <AppRoutes></AppRoutes>
      </div>
    </div>
  );
}

export default App;
