import React, { useState, useEffect } from "react";
import "./comitePage.css";
import { Link } from "react-router-dom";
import { db } from "../../config/firebaseconfig";
import { doc, collection, getDocs, deleteDoc } from "firebase/firestore";
import {
	getStorage,
	ref,
	deleteObject,
	listAll,
	getDownloadURL,
} from "firebase/storage";
import toast from "react-hot-toast";
import { getAuth } from "firebase/auth";
import { Helmet } from "react-helmet";

function ComitePage() {
	const [membres, setMembres] = useState([]);
	const [loading, setLoading] = useState(true);
	const auth = getAuth();
	const user = auth.currentUser;
	const storage = getStorage();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const querySnapshot = await getDocs(collection(db, "membres"));
				var membresArray = [];
				querySnapshot.forEach((doc) => {
					membresArray.push({ id: doc.id, ...doc.data() });
				});

				const promises = membresArray.map((membre) => {
					// return listAll(ref(storage, "membres/" + membre.id)).then((res) => {
					// 	let pathImage = res.items[res.items.length - 1]._location.path;
					// 	membre.pathImage = pathImage;
					// 	return getDownloadURL(ref(storage, membre.pathImage)).then(
					// 		(url) => {
					// 			membre.pathImageDownloadUrl = url;
					// 		}
					// 	);
					// });
				});

				Promise.all(promises)
					.then(() => {
						setMembres(membresArray);
						setLoading(false);
					})
					.catch((error) => {
						console.error(error);
						setLoading(false);
					});
			} catch (error) {
				console.error("Erreur lors de la récupération des données", error);
			}
		};

		fetchData();
	}, []);

	const handleDelete = async (membre) => {
		if (window.confirm("Êtes-vous sûr de vouloir supprimer ce membre ?")) {
			try {
				// Logique de suppression
				await deleteDoc(doc(db, "membres", membre.id));
				setMembres((prevMembres) =>
					prevMembres.filter(
						(element) =>
							!(element.Nom === membre.Nom && element.Poste === membre.Poste)
					)
				);

				const deleteRef = ref(storage, membre.pathImage); //Todo car a voir avec la récup pour affichage en arrière plan
				deleteObject(deleteRef)
					.then(() => {
						toast.success("Membre supprimé", {
							position: "bottom-center",
						});
					})
					.catch((error) => {
						console.error(error);
					});
			} catch (error) {
				console.error("Erreur lors de la suppression du membre :", error);
			}
		}
	};
	const imagePath = `${process.env.PUBLIC_URL}/images/logoSaviese.jpg`;

	const priorityOrder = {
		Président: 1,
		Caissier: 2,
		Membre: 3,
	};

	// Trier les membres par priorité de poste
	const sortedMembres = membres.sort((a, b) => {
		const priorityA = priorityOrder[a.Poste] || 4;
		const priorityB = priorityOrder[b.Poste] || 4;
		return priorityA - priorityB;
	});

	return (
		<div className="animate-fade-up font-[sans-serif] text-[#333]">
			<Helmet>
				<title>Le comité - Jeunesse de Savièse</title>
				<meta
					name="description"
					content="Découvrez le comité de jeunesse où les idées fusent et les projets s'épanouissent."
				/>
				<meta
					name="keywords"
					content="jeunesse, Savièse, Valais, comité. comité jeunesse Savièse"
				/>
				Balises Open Graph
				<meta
					property="og:title"
					content="La jeunesse de Savièse - Le comité"
				/>
				<meta
					property="og:description"
					content="Découvrez le comité de jeunesse où les idées fusent et les projets s'épanouissent."
				/>
				<meta property="og:image" content={imagePath} />{" "}
				{/* Remplacez par l'URL de votre image */}
				<meta property="og:url" content="jeunessesaviese.ch/comite" />{" "}
				{/* Remplacez par l'URL de votre page */}
				<meta property="og:type" content="website" />
				<meta property="og:locale" content="fr_FR" />
			</Helmet>
			<div className="lg:max-w-5xl max-w-3xl mx-auto">
				<div className="max-w-2xl mx-auto text-center ">
					<h2 className="text-3xl font-extrabold">Le comité</h2>
					<p className="text-lg mt-4 leading-8 pb-8">
						Le comité de jeunesse, où les idées fusent et les projets
						s'épanouissent
					</p>
				</div>
				<div className="pb-8 text-center">
					{user && (
						<Link to="/comite/create" className="inline-block">
							<button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded ">
								Ajouter un membre
							</button>
						</Link>
					)}
				</div>
				{loading ? (
					<div className="text-center py-8">
						<p className="text-lg text-gray-700">Chargement en cours...</p>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-10 animate-spin fill-blue-600 block mx-auto"
							viewBox="0 0 24 24">
							<path
								d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
								data-original="#000000"
							/>
						</svg>
					</div>
				) : (
					<div className="grid grid-cols-3 gap-x-8 gap-y-10 px-6 lg:px-18 xl:grid-cols-3 justify-center pb-8">
						{sortedMembres.map((membre) => (
							<div
								key={membre.Prenom}
								className="border rounded-md overflow-hidden max-w-[280px]">
								<img
									// src={membre.pathImageDownloadUrl}
									src={require("../../images/avatar.png")}
									alt="Photo du membre du comité"
								/>
								<div className=" text-center">
									<h4 className="text-base font-extrabold text-center">
										{membre.Prenom + " " + membre.Nom}
									</h4>
									<p className="text-lg mt-1 pb-2 ">{membre.Poste}</p>
									{user && (
										<div className="space-x-4 mt-4">
											{" "}
											<Link to={`/comite/modify/${membre.id}`}>
												<button
													className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
													type="button">
													Modifier
												</button>
											</Link>
											<button
												type="button"
												className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
												onClick={() => handleDelete(membre)}>
												Supprimer
											</button>
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
}

export default ComitePage;
