import React, { useState, useEffect, useRef } from "react";
import Evenement from "../../../Model/evenementModel";
import "./createEvenement.css";
import { useParams } from "react-router-dom";
import EvenementFormBase from "./formBaseEvenement";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebaseconfig";
import {
	getStorage,
	ref,
	uploadBytes,
	listAll,
	getDownloadURL,
} from "firebase/storage";

function formatDate(evenementDate) {
	const dateTimeObject = new Date(evenementDate);
	const month = (dateTimeObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
	const year = dateTimeObject.getFullYear().toString().substring(2);
	const formattedDateTime = month + year;
	return formattedDateTime;
}
function firstWord(evenementNom) {
	const mots = evenementNom.split(" ");
	return mots[0];
}

function CreateEvenement() {
	const [evenement, setEvenement] = useState(new Evenement("", "", ""));
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const storage = getStorage();
	const inputFile = useRef(null);

	const handleImageChange = (e) => {
		setImage(null);
		try {
			if (e.target.files[0]) {
				setImage(e.target.files[0]);
				const reader = new FileReader();
				reader.onloadend = () => {
					setImagePreview(reader.result);
				};
				reader.readAsDataURL(e.target.files[0]);
			} else {
				setImagePreview(null);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setEvenement({ ...evenement, [name]: value });
	};

	const removeImagePreview = () => {
		setImage(null);
		setImagePreview(null);
		inputFile.current.value = "";
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const formattedDateTime = formatDate(evenement.Date);
			const newImageRef = ref(
				storage,
				"evenements/" +
					firstWord(evenement.Nom) +
					formattedDateTime +
					"/" +
					image.name
			);
			uploadBytes(newImageRef, image).then(() => {
				console.log("Uploaded a file!");
			});

			await setDoc(
				doc(db, "evenements", firstWord(evenement.Nom) + formattedDateTime),
				{
					Nom: evenement.Nom,
					Date: evenement.Date,
					Lieu: evenement.Lieu,
					AfterMovie: evenement.AfterMovie,
				}
			);
		} catch (error) {
			console.error("Erreur lors de la création de l'événement :", error);
		}
		try {
		} catch (errorUpload) {
			console.error(
				"Erreur lors de la création de l'espace de stockage :",
				errorUpload
			);
		}
	};

	return (
		<EvenementFormBase
			evenement={evenement}
			handleChange={handleChange}
			handleImageChange={handleImageChange}
			onSubmit={handleSubmit}
			title="Création d'un événement"
			imagePreview={imagePreview}
			removeImagePreview={removeImagePreview}
			inputFile={inputFile}
		/>
	);
}

function UpdateEvenement() {
	const [evenement, setEvenement] = useState(new Evenement("", "", ""));
	const { evenementId } = useParams();
	const docRef = doc(db, "evenements", evenementId);
	const [image, setImage] = useState(null);
	const [imageUrl, setImageUrl] = useState("");
	const storage = getStorage();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					const evenementData = { id: evenementId, ...docSnap.data() };
					setEvenement(evenementData);

					const res = await listAll(ref(storage, "evenements/" + evenementId));
					const pathImage = res.items[res.items.length - 1]._location.path;
					evenementData.pathImage = pathImage;

					const url = await getDownloadURL(
						ref(storage, evenementData.pathImage)
					);
					evenementData.pathImageDownloadUrl = url;
				} else {
					console.log("No such document!");
				}
			} catch (error) {
				console.error("Erreur lors de la récupération des données", error);
			}
		};
		fetchData();
	}, []);

	const handleImageChange = (e) => {
		if (e.target.files[0]) {
			console.log(e.target.files[0]);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setEvenement({ ...evenement, [name]: value });
	};

	const updateEvenementFinal = async (e) => {
		e.preventDefault();
		try {
			await updateDoc(docRef, {
				Nom: evenement.Nom,
				Date: evenement.Date,
				Lieu: evenement.Lieu,
				AfterMovie: evenement.AfterMovie,
			}).then((res) => console.log("ok"));
		} catch (error) {
			console.error("Erreur lors de la mise à jour du evenement :", error);
		}
	};

	return (
		<EvenementFormBase
			evenement={evenement}
			handleChange={handleChange}
			handleImageChange={handleImageChange}
			onSubmit={updateEvenementFinal}
			imagePreview={evenement.pathImageDownloadUrl}
			title={"Mise à jour de l'événement"}
		/>
	);
}

export { CreateEvenement, UpdateEvenement };
