import React from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import { Helmet } from "react-helmet";

const MembreFormBase = ({
	membre,
	handleChange,
	onSubmit,
	title,
	imagePreview,
	removeImagePreview,
	handleImageChange,
	inputFile,
}) => {
	return (
		<div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
			<Helmet>
				<title>Gestion du comité - Jeunesse de Savièse</title>
				<meta
					name="description"
					content="Page de création ou modification du comité"
				/>
				<meta name="robots" content="noindex, nofollow" />{" "}
				{/* Empêche l'indexation par les moteurs de recherche */}
			</Helmet>
			<h2 className="text-xl mb-4">{title}</h2>
			<form onSubmit={onSubmit}>
				<div className="mb-4">
					<label className="block text-gray-700  font-bold mb-2">
						Prénom :
					</label>
					<input
						className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						type="text"
						name="Prenom"
						value={membre.Prenom}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700  font-bold mb-2">Nom :</label>
					<input
						className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						type="text"
						name="Nom"
						value={membre.Nom}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="mb-4">
					<label className="block text-gray-700  font-bold mb-2">Poste :</label>
					<select
						className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						name="Poste"
						value={membre.Poste}
						onChange={handleChange}>
						<option value="Membre">Membre</option>
						<option value="Président">Président</option>
						<option value="Caissier">Caissier</option>
					</select>
				</div>
				{title === "Création d'un membre du comité" && (
					<div className="mb-4">
						<label className="block text-gray-700  font-bold mb-2">
							Photo :
						</label>

						<input
							className="block w-full  text-slate-500
              file:mr-4 file:py-2 file:px-4 file:rounded-md
              file:border-0 file: file:font-semibold
              file:bg-blue-50 file:text-blue-700
              hover:file:bg-blue-100"
							type="file"
							onChange={handleImageChange}
							ref={inputFile}
						/>
						{imagePreview && (
							<div className="relative">
								<RiCloseCircleLine
									className="absolute top-0 right-0 cursor-pointer text-red-500"
									onClick={removeImagePreview}
								/>
								<img
									src={imagePreview}
									alt="Image Preview"
									className="mb-2 max-w-full"
								/>
							</div>
						)}
					</div>
				)}
				<button
					className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
					type="submit">
					{title === "Création d'un membre du comité"
						? "Créer"
						: "Mettre à jour"}{" "}
					le membre
				</button>
			</form>
		</div>
	);
};

export default MembreFormBase;
