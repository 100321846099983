// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDKKeQF--0hyA92AleDZJ-yaFXLk1fatKw",
  authDomain: "js-1965.firebaseapp.com",
  databaseURL: "https://js-1965-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "js-1965",
  storageBucket: "js-1965.appspot.com",
  messagingSenderId: "789123595784",
  appId: "1:789123595784:web:5a8d8a13bc7f943c61dbbd",
  measurementId: "G-ZXP39HEXHD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { db, auth };
