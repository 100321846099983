import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const ProtectedRoute = ({ children }) => {
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const auth = getAuth();
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser(user);
			} else {
				setUser(null);
			}
			setIsLoading(false);
		});

		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, []);

	if (isLoading) {
		// Display loading indicator while checking authentication status
		return <div>Loading...</div>;
	}

	if (user) {
		// User is authenticated, allow access to the route
		return children;
	} else {
		// User is not authenticated, redirect to login page
		return <Navigate to="/login" />;
	}
};

export default ProtectedRoute;
