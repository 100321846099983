import React, { useState, useEffect, useRef } from "react";
import Membre from "../../../Model/membreModel";
import "./formBaseMembre.css";
import { useParams } from "react-router-dom";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebaseconfig";
import MembreFormBase from "./formBaseMembre";
import {
	getStorage,
	ref,
	uploadBytes,
	listAll,
	getDownloadURL,
} from "firebase/storage";

function firstWord(evenementNom) {
	const mots = evenementNom.split(" ");
	return mots[0];
}
function CreateMembre() {
	const [membre, setMembre] = useState(new Membre("", "", "Membre", ""));
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const storage = getStorage();
	const inputFile = useRef(null);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setMembre({ ...membre, [name]: value });
	};
	const handleImageChange = (e) => {
		setImage(null);
		try {
			if (e.target.files[0]) {
				setImage(e.target.files[0]);
				const reader = new FileReader();
				reader.onloadend = () => {
					setImagePreview(reader.result);
				};
				reader.readAsDataURL(e.target.files[0]);
			} else {
				setImagePreview(null);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const removeImagePreview = () => {
		setImage(null);
		setImagePreview(null);
		inputFile.current.value = "";
	};

	const createMembreFinal = async (e) => {
		e.preventDefault();

		try {
			// const newImageRef = ref(
			// 	storage,
			// 	"membres/" + firstWord(membre.Nom) + membre.Poste + "/" + image.name
			// );
			// uploadBytes(newImageRef, image).then(() => {
			// 	console.log("Uploaded a file!");
			// });
			await setDoc(doc(db, "membres", firstWord(membre.Nom) + membre.Poste), {
				Nom: membre.Nom,
				Prenom: membre.Prenom,
				Poste: membre.Poste,
			});
		} catch (error) {
			console.error("Erreur lors de la création du membre :", error);
		}
	};

	return (
		<MembreFormBase
			membre={membre}
			handleChange={handleChange}
			onSubmit={createMembreFinal}
			title="Création d'un membre du comité"
			imagePreview={imagePreview}
			removeImagePreview={removeImagePreview}
			handleImageChange={handleImageChange}
			inputFile={inputFile}
		/>
	);
}

function UpdateMembre() {
	const [membre, setMembre] = useState(new Membre("", "", "Membre", ""));
	const { membreId } = useParams();
	const docRef = doc(db, "membres", membreId);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					setMembre(docSnap.data());
				} else {
					console.error("No such document!");
				}
			} catch (error) {
				console.error("Erreur lors de la récupération des données", error);
			}
		};

		fetchData();
	}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setMembre({ ...membre, [name]: value });
	};

	const updateMembreFinal = async (e) => {
		e.preventDefault();

		try {
			await updateDoc(docRef, {
				Nom: membre.Nom,
				Prenom: membre.Prenom,
				Poste: membre.Poste,
			});
		} catch (error) {
			console.error("Erreur lors de la mise à jour du membre :", error);
		}
	};

	return (
		<MembreFormBase
			membre={membre}
			handleChange={handleChange}
			onSubmit={updateMembreFinal}
			imagePreview={membre.pathImageDownloadUrl}
			title="Mise à jour d'un membre du comité"
		/>
	);
}

export { CreateMembre, UpdateMembre };
