import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function ChartePage() {
	return (
		<div className="animate-fade-up max-w-5xl mx-auto text-[#333] font-[sans-serif] my-4">
			<Helmet>
				<title>Charte - Jeunesse de Savièse</title>
				<meta name="description" content="Page pour la charte de la jeunesse" />
			</Helmet>
			<div className="text-center max-w-2xl mx-auto">
				<h2 className="text-3xl font-extrabold text-center mb-4">
					Charte de la jeunesse de Savièse{" "}
				</h2>
				<p className="text-lg text-justify px-8">
					Par la présente charte, je m’engage auprès de la jeunesse de Savièse à
					respecter tous les membres et de payer une cotisation annuelle de CHF
					50.-. <br />
					<br />
					Une assemblée générale est tenue 1 fois par année afin de présenter
					les comptes et de repasser en revue les sorties de l’année, les
					changements du comité et la présentation des futures sorties. <br />
					<br />
					Pour toutes personnes mineures l'accord du représentant légal est
					requise car nous déclinons toutes responsabilités en cas de problème.
					Nous pensons que chaque personne est responsable.
				</p>
			</div>
		</div>
	);
}

export default ChartePage;
