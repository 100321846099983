import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function ResponsabilitePage() {
	return (
		<div className="animate-fade-up max-w-5xl mx-auto text-[#333] font-[sans-serif] my-4">
			<Helmet>
				<title>Responsabilité - Jeunesse de Savièse</title>
				<meta
					name="description"
					content="Page pour les informations concernant la responsabilité des personnes inscrites"
				/>
			</Helmet>
			<div className="text-center max-w-2xl mx-auto">
				<h2 className="text-3xl font-extrabold text-center mb-4 pt-8">
					Responsabilités des Personnes Mineures dans la Jeunesse de Savièse{" "}
				</h2>
				<div className="text-lg text-justify px-8">
					<p>
						En tant que membre de la jeunesse de Savièse, nous accordons une
						importance capitale à la sécurité et au bien-être de tous nos
						membres, y compris ceux qui sont mineurs. Ainsi, pour garantir un
						environnement sûr et respectueux, nous avons établi les conditions
						suivantes concernant les responsabilités des personnes mineures :
					</p>
					<ol>
						<li className="pt-8">
							<strong>Consentement Éclairé :</strong> Avant de participer à
							toute activité, les personnes mineures doivent obtenir le
							consentement éclairé de leur représentant légal, qui reconnaît et
							accepte les risques associés à ladite activité.
						</li>
						<li className="pt-8">
							<strong>Respect des Règles et des Autres Membres :</strong> Les
							personnes mineures sont tenues de respecter toutes les règles et
							les normes de comportement établies par la jeunesse de Savièse.
							Elles doivent également respecter les autres membres et agir de
							manière responsable envers eux.
						</li>
						<li className="pt-8">
							<strong>Communication Ouverte :</strong> En cas de problème ou de
							question concernant leur participation à une activité ou leur
							adhésion à l'association, les personnes mineures sont encouragées
							à communiquer ouvertement avec les responsables de la jeunesse de
							Savièse ou avec leur représentant légal.
						</li>
						<li className="pt-8">
							<strong>Engagement de Responsabilité :</strong> En participant aux
							activités de la jeunesse de Savièse, les personnes mineures et
							leur représentant légal reconnaissent et acceptent que
							l'association décline toute responsabilité en cas d'accident, de
							blessure ou de tout autre incident survenant pendant les activités
							organisées.
						</li>
					</ol>
					<p className="pt-8 pb-8">
						En respectant ces conditions, nous visons à créer un environnement
						inclusif et sécurisé où chaque membre, qu'il soit mineur ou majeur,
						peut participer pleinement et en toute confiance aux activités de la
						jeunesse de Savièse.
					</p>
				</div>
			</div>
		</div>
	);
}

export default ResponsabilitePage;
