import React from "react";
import { Link } from "react-router-dom";
import twintLogo from "../../images/twint_logo.png";
import { Helmet } from "react-helmet";

function NosSponsorsPage() {
	return (
		<div className="animate-fade-up max-w-5xl mx-auto text-[#333] font-[sans-serif] my-4">
			<Helmet>
				<title>Sponsors et don - Jeunesse de Savièse</title>
				<meta
					name="description"
					content="Page pour montrer les différents sponsors ainsi que les différentes manières de faire des dons"
				/>
				<meta
					name="keywords"
					content="jeunesse, Savièse, Valais, planning, sponsors jeunesse Savièse, dons, dons pour la jeunesse"
				/>
			</Helmet>
			<div className="text-center max-w-2xl mx-auto">
				<h2 className="text-3xl font-extrabold text-center mb-4">
					Faire un don
				</h2>
				<p className="">Afin de soutenir notre jeunesse</p>
			</div>
			<div className="mt-4">
				<div className="grid md:grid-cols-2 items-center gap-4">
					<div>
						<Link to="https://pay.raisenow.io/gqvhn">
							<img
								src={twintLogo}
								className="w-full object-contain rounded-md  size-48"
								alt="Logo du moyen de paiement twint"
							/>
						</Link>
					</div>
					<div className="p-4 hidden md:block">
						<h3 className="text-2xl font-extrabold mb-4">Twint</h3>
						<p className="">
							Pas le biais de twint de manière anonyme et rapide
						</p>
						<Link to="https://pay.raisenow.io/gqvhn">
							<button
								type="button"
								className="px-6 py-2 mt-8 rounded text-white tracking-wider font-medium outline-none border-2 border-[#ff6348] bg-[#ff6348] hover:bg-transparent hover:text-black transition-all duration-300">
								Don Twint
							</button>
						</Link>
					</div>
				</div>
			</div>{" "}
			<p className="text-lg text-center py-4 font-extrabold"> Ou </p>
			<div className="text-center">
				<h3 className="text-2xl font-extrabold mb-4">Versement</h3>
				<p className="mt-4 italic">
					Jeunesse de Savièse
					<br />
					CH32 8080 8001 0143 1950 7<br />
					1965 Savièse
					<br />
					Suisse
					<br />
				</p>
			</div>
			<hr className="mx-auto my-4 border-t-2 border-gray-300 w-3/5" />
			{/* <div className="text-center max-w-2xl mx-auto">
				<h2 className="text-3xl font-extrabold text-center mb-4">
					Nos sponsors ♥
				</h2>
				<p className="">
					Ils soutiennent la jeunesse lors des différents événements
				</p>
			</div>
			<section className="bg-white w-full flex justify-center items-center py-8">
				<div className="max-w-screen-xl px-4 pt-4 mx-auto">
					<div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 sm:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
						<div className="text-center">
							<img
								className="flex items-center lg:justify-center rounded-full mx-auto"
								target="_blank"
								src={require("../../images/penalty.jpg")}
								alt="Image description"
							/>
							<p className="mt-2">Le penalty</p>
						</div>
						<div className="text-center">
							<img
								className="flex items-center lg:justify-center rounded-full mx-auto"
								target="_blank"
								src={require("../../images/penalty.jpg")}
								alt="Image description"
							/>
							<p className="mt-2">Texte 2</p>
						</div>
						<div className="text-center">
							<img
								className="flex items-center lg:justify-center rounded-full mx-auto"
								target="_blank"
								src={require("../../images/penalty.jpg")}
								alt="Image description"
							/>
							<p className="mt-2">Texte 3</p>
						</div>
						<div className="text-center">
							<img
								className="flex items-center lg:justify-center rounded-full mx-auto"
								target="_blank"
								src={require("../../images/penalty.jpg")}
								alt="Image description"
							/>
							<p className="mt-2">Texte 4</p>
						</div>
						<div className="text-center">
							<img
								className="flex items-center lg:justify-center rounded-full mx-auto"
								target="_blank"
								src={require("../../images/penalty.jpg")}
								alt="Image description"
							/>
							<p className="mt-2">Texte 5</p>
						</div>
						<div className="text-center">
							<img
								className="flex items-center lg:justify-center rounded-full mx-auto"
								target="_blank"
								src={require("../../images/pda.webp")}
								alt="Image description"
							/>
							<p className="mt-2">Part des Anges</p>
						</div>
					</div>
				</div>
			</section> */}
		</div>
	);
}

export default NosSponsorsPage;
