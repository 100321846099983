import "./navBar.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useRef } from "react";
import { Logout } from "../../Pages/auth/login";

function NavBar() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const auth = getAuth();
	const [isOpen, setIsOpen] = useState(false);

	const menuRef = useRef(null);
	const buttonRef = useRef(null);

	const handleOutsideClick = (event) => {
		if (buttonRef.current && buttonRef.current.contains(event.target)) {
			return;
		}
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		// Listener pour vérifier l'état de connexion de l'utilisateur
		onAuthStateChanged(auth, (user) => {
			if (user) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
		});

		// Initialisation du canvas
		var c = document.getElementById("canv");
		var $ = c.getContext("2d");

		var col = function (x, y, r, g, b) {
			$.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
			$.fillRect(x, y, 1, 1);
		};
		var R = function (x, y, t) {
			return Math.floor(192 + 64 * Math.cos((x * x - y * y) / 300 + t));
		};
		var G = function (x, y, t) {
			return Math.floor(
				192 +
					64 *
						Math.sin((x * x * Math.cos(t / 4) + y * y * Math.sin(t / 3)) / 300)
			);
		};
		var B = function (x, y, t) {
			return Math.floor(
				192 +
					64 *
						Math.sin(
							5 * Math.sin(t / 9) +
								((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100
						)
			);
		};
		var t = 0;
		var x;
		var y;
		var run = function () {
			for (x = 0; x <= 35; x++) {
				for (y = 0; y <= 35; y++) {
					col(x, y, R(x, y, t), G(x, y, t), B(x, y, t));
				}
			}
			t = t + 0.03;
			window.requestAnimationFrame(run);
		};
		run();

		document.addEventListener("mousedown", handleOutsideClick);
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	return (
		<>
			<div>
				<div id="logo" className="title-container">
					<Link to="/">
						<img
							className="title w-3/12 md:w-52 absolute top-8 md:top-2 top-3 md:left-12 left-6 "
							src={require("../../images/logoSaviese.png")}
							alt="logo de la jeunesse de Savièse"
						/>
					</Link>
				</div>

				<div className="canvas-container">
					<canvas id="canv" width="36" height="100"></canvas>
				</div>
				<nav className="bg-gray-800 text-black">
					<div className="menu animate-fade px-10 md:flex">
						<div className="flex items-center justify-between md:w-auto">
							&nbsp;
							<div className="md:hidden  flex items-center rounded-lg bg-white">
								<button
									className="mobile-menu-button rounded-xl "
									onClick={() => setIsOpen(!isOpen)}>
									<svg
										ref={buttonRef}
										xmlns="http://www.w3.org/2000/svg"
										width="36"
										height="36"
										viewBox="0 0 25 25">
										<title>bars-3-bottom-left</title>
										<g fill="none">
											<path
												d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
												stroke="currentColor"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"></path>
										</g>
									</svg>
								</button>
							</div>
						</div>
						<div
							ref={menuRef}
							className={`${
								isOpen ? "block" : "hidden"
							} md:block rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 divide-solid divide-x focus:outline-none animate-fade md:flex md:flex-row flex-col items-center justify-start md:space-x-1 navigation-menu pb-3 md:pb-0 navigation-menu`}>
							<Link
								to="/"
								className="py-2 px-3 block "
								onClick={() => setIsOpen(!isOpen)}>
								La jeunesse
							</Link>

							<Link
								to="/comite"
								className="py-2 px-3 block"
								onClick={() => setIsOpen(false)}>
								Le comité
							</Link>
							<Link
								to="/planning"
								className="py-2 px-3 block"
								onClick={() => setIsOpen(false)}>
								Planning
							</Link>
							<Link
								to="/dons"
								className="py-2 px-3 block"
								onClick={() => setIsOpen(false)}>
								Faire un don
							</Link>
							<Link
								to="/rjv2025"
								className="py-2 px-3 block "
								onClick={() => setIsOpen(false)}>
								RJV 2025
							</Link>
							<Link
								to="/inscription"
								className="py-2 px-3 block divide-y divide-blue-200"
								onClick={() => setIsOpen(false)}>
								Inscription
							</Link>
							<Link
								to="/contact"
								className="py-2 px-3 block divide-y divide-blue-200"
								onClick={() => setIsOpen(false)}>
								Contact
							</Link>
							{/* {isLoggedIn ? (
								<button
									className=" py-2 px-3 block   "
									onClick={() => Logout()}>
									Logout
								</button>
							) : (
								<Link to="/login" className="py-2 px-3 block ">
									Login
								</Link>
							)} */}
						</div>
					</div>
				</nav>
			</div>
		</>
	);
}

export default NavBar;
