import React from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import { Helmet } from "react-helmet";

const EvenementFormBase = ({
  evenement,
  handleChange,
  handleImageChange,
  onSubmit,
  title,
  imagePreview,
  removeImagePreview,
  inputFile,
}) => {
  return (
    <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <Helmet>
        <title>Gestion des événements - Jeunesse de Savièse</title>
        <meta
          name="description"
          content="Page de création ou modification d'événement"
        />
        <meta name="robots" content="noindex, nofollow" />{" "}
        {/* Empêche l'indexation par les moteurs de recherche */}
      </Helmet>
      <h2 className="text-xl mb-4">{title}</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700  font-bold mb-2">
            Nom de l'événement :
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline required"
            type="text"
            name="Nom"
            value={evenement.Nom}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700  font-bold mb-2">
            Date et heure de l'événement :
          </label>
          <input
            className="shadow required appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="month"
            name="Date"
            value={evenement.Date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700  font-bold mb-2">Lieu :</label>
          <input
            className="shadow required appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="Lieu"
            value={evenement.Lieu}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700  font-bold mb-2">
            Lien de l'after Movie :
          </label>
          <input
            className="shadow  appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="AfterMovie"
            value={evenement.AfterMovie}
            onChange={handleChange}
          />
        </div>
        {title === "Création d'un événement" && (
          <div className="mb-4">
            <label className="block text-gray-700  font-bold mb-2">
              Image :
            </label>

            <input
              className="block w-full  text-slate-500
              file:mr-4 file:py-2 file:px-4 file:rounded-md
              file:border-0 file: file:font-semibold
              file:bg-blue-50 file:text-blue-700
              hover:file:bg-blue-100 required"
              type="file"
              onChange={handleImageChange}
              ref={inputFile}
              required
            />
            {imagePreview && (
              <div className="relative">
                <RiCloseCircleLine
                  className="absolute top-0 right-0 cursor-pointer text-red-500"
                  onClick={removeImagePreview}
                />
                <img
                  src={imagePreview}
                  alt="Image preview"
                  className="mb-2 max-w-full"
                />
              </div>
            )}
          </div>
        )}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {title === "Création d'un événement" ? "Créer" : "Mettre à jour"}{" "}
          l'événement
        </button>
      </form>
    </div>
  );
};

export default EvenementFormBase;
