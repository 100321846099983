import "./jeunessePage.css";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class JeunessePage extends React.Component {
  render() {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Jeunesse de Savièse",
      url: "https://jeunessesaviese.ch/",
    };

    return (
      <>
        <Helmet>
          <title>Jeunesse de Savièse</title>
          <meta
            name="description"
            content="Présentation de la jeunesse de Savièse qui est une organisation dynamique située dans le Canton du Valais à Savièse. Découvrez nos événements, activités et comment nous promouvons le développement de la jeunesse locale."
          />
          <meta
            name="keywords"
            content="jeunesse, Savièse, Valais, organisation, événements, activités, développement, social, culturel"
          />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
            integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
          />
        </Helmet>
        <link rel="stylesheet" href="icons.css " />
        <div className="animate-fade-up grid md:grid-cols-2 items-center md:gap-8  font-[sans-serif] text-[#333]  px-8 lg:gap-8 max-lg:max-w-2xl max-md:max-w-md mx-auto">
          <div className=" max-md:text-center lg:pt-20 text-lg">
            {" "}
            {/* Ajustement de la taille de la police */}
            <h2 className="md:text-4xl text-4xl md:leading-10 font-extrabold text-[#333] mb-4 text-center">
              {" "}
              {/* Ajustement de la taille de la police */}
              La jeunesse de Savièse
            </h2>
            <p className="mt-6  text-[#666] leading-relaxed text-center">
              {" "}
              Située au cœur du Valais, la jeunesse de Savièse est une équipe de
              plus de 130 membres motivés. L'objectif est de promouvoir le
              bien-être et le développement des jeunes de la région.
              <span className="hidden md:inline">
                {" "}
                Régulièrement, une variété d'événements et d'activités est
                organisée, que ce soit des fêtes traditionnelles, des projets
                sociaux ou des événements culturels. Le groupe est très
                diversifié et collaboratif. Des liens sont créés entre les
                générations, renforçant ainsi le tissu social de notre belle
                région.
              </span>{" "}
              <span className="block text-center">
                Venez nous rejoindre !
              </span>{" "}
            </p>
            <div className="flex flex-wrap gap-4">
              <div className="card">
                <i className="card-icon fa-brands fa-instagram"></i>
                <a
                  href="https://www.instagram.com/jeunessedesaviese/"
                  target="_blank"
                >
                  jeunessedesaviese
                </a>
              </div>
              <div className="card">
                <i className="card-icon far fa-envelope "></i>
                <a href="mailto:jeunessedesaviese@gmail.com" target="_blank">
                  jeunessedesaviese@gmail.com
                </a>
              </div>

              <div className="card">
                <i className="card-icon fa-brands fa-facebook"></i>
                <a
                  href="https://www.facebook.com/people/Jeunesse-de-Savi%C3%A8se/100067419638062/"
                  target="_blank"
                >
                  Jeunesse de Savièse
                </a>
              </div>
              <div className="flex flex-auto justify-center items-center">
                <Link to="/inscription">
                  <button className="px-6 py-3 mb-8  rounded text-white text-lg tracking-wider font-medium outline-none border-2 border-[#ff6348] bg-[#ff6348] hover:bg-transparent hover:text-black transition-all duration-300  ">
                    Nous rejoindre ?
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="md:h-fit  flex flex-auto justify-center items-center hover:scale-110 transition-transform duration-300 click:scale-0">
            <img
              src={require("../../images/Groupe.jpg")}
              className="rounded-lg object-cover w-5/6 h-5/6"
              alt="Photo de groupe de la jeunesse"
            />
          </div>
        </div>
      </>
    );
  }
}

export default JeunessePage;
