import "./planningPage.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/firebaseconfig";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  getStorage,
  ref,
  deleteObject,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

function PlanningPage() {
  const [evenements, setEvenements] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const storage = getStorage();

  const user = auth.currentUser;

  useEffect(() => {
    const fetchData = async () => {
      // try {
      const querySnapshot = await getDocs(collection(db, "evenements"));
      const evenementsArray = [];
      querySnapshot.forEach((doc) => {
        evenementsArray.push({ id: doc.id, ...doc.data() });
      });
      const promises = evenementsArray.map((evenement) => {
        return listAll(ref(storage, "evenements/" + evenement.id)).then(
          (res) => {
            let pathImage = res.items[res.items.length - 1]._location.path;
            evenement.pathImage = pathImage;
            return getDownloadURL(ref(storage, evenement.pathImage)).then(
              (url) => {
                evenement.pathImageDownloadUrl = url;
              }
            );
          }
        );
      });

      Promise.all(promises)
        .then(() => {
          setEvenements(evenementsArray);
          evenementsArray.sort((a, b) => new Date(a.Date) - new Date(b.Date));
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    };

    fetchData();
  }, []);

  const handleDelete = async (evenement) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce membre ?")) {
      try {
        const deleteRef = ref(storage, evenement.pathImage); //Todo car a voir avec la récup pour affichage en arrière plan
        deleteObject(deleteRef)
          .then(() => {
            toast.success("Evénement supprimé", {
              position: "bottom-center",
            });
          })
          .catch((error) => {
            console.error(error);
          });
        await deleteDoc(doc(db, "evenements", evenement.id));
        setEvenements((prevEvenement) =>
          prevEvenement.filter(
            (element) =>
              !(
                element.Nom === evenement.Nom && element.Date === evenement.Date
              )
          )
        );
      } catch (error) {
        toast.error("Erreur lors de la suppression", {
          position: "bottom-center",
        });
        console.error("Erreur lors de la suppression de l'event :", error);
      }
    }
  };

  const formatDate = (dateString, date) => {
    const dateObject = new Date(dateString);
    if (date) {
      const year = dateObject.getFullYear();
      var monthName = dateObject.toLocaleString("fr-FR", { month: "long" });
      monthName = monthName[0].toUpperCase() + monthName.slice(1);
      return `${monthName} ${year}`;
    } else {
      const hours = dateObject.getHours().toString().padStart(2, "0");
      const minutes = dateObject.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    }
  };

  return (
    <>
      <Helmet>
        <title>Planning - Jeunesse de Savièse</title>
        <meta name="description" content="Voici le planning de la jeunesse" />
        <meta
          name="keywords"
          content="jeunesse, Savièse, Valais, planning, activité, événement"
        />
      </Helmet>
      <div className="animate-fade-up animate-once duration-400 animate-ease-in-out mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className=" text-3xl font-bold tracking-tight text-gray-900 text-center sm:text-4xl">
          Planning
        </h2>
        {user && (
          <Link to="/evenement/create" className="inline-block">
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
              Ajouter un événement
            </button>
          </Link>
        )}
        {loading ? (
          <div className="text-center py-8">
            <p className="text-lg text-gray-700">Chargement en cours...</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-10 animate-spin fill-blue-600 block mx-auto"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 22c5.421 0 10-4.579 10-10h-2c0 4.337-3.663 8-8 8s-8-3.663-8-8c0-4.336 3.663-8 8-8V2C6.579 2 2 6.58 2 12c0 5.421 4.579 10 10 10z"
                data-original="#000000"
              />
            </svg>
          </div>
        ) : (
          <div className="mx-auto grid max-w-7xl gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 px-6 py-8">
            {evenements.map((evenement) => (
              <div className="relative group" key={evenement.id}>
                {evenement.AfterMovie ? (
                  <a
                    href={`${evenement.AfterMovie}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={evenement.id}
                    className="relative group block"
                  >
                    <div
                      className="bg-cover bg-center h-80 rounded-lg overflow-hidden transition-opacity duration-300 ease-in-out"
                      style={{
                        backgroundImage: `url(${evenement.pathImageDownloadUrl})`,
                      }}
                    >
                      <div className="absolute rounded inset-0 bg-black opacity-50 transition-opacity duration-300 ease-in-out group-hover:opacity-0"></div>
                      <div className="absolute inset-0 flex flex-col items-center justify-center opacity-100 group-hover:opacity-0 transition-opacity duration-300 ease-in-out">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-10 w text-white mb-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          width="300"
                          height="200"
                          stroke="currentColor"
                          strokeWidth="1.5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16 16c0 1.104-.896 2-2 2h-12c-1.104 0-2-.896-2-2v-8c0-1.104.896-2 2-2h12c1.104 0 2 .896 2 2v8zm8-10l-6 4.223v3.554l6 4.223v-12z"
                          />
                        </svg>

                        <p className="text-white text-lg">Vers l'after movie</p>
                      </div>
                      <div className="absolute bottom-0 left-0 p-4">
                        <h3 className="text-lg font-semibold text-white mb-1 bg-black bg-opacity-75 px-2 py-1 rounded">
                          {evenement.Nom}
                        </h3>
                        <p className="text-white bg-black bg-opacity-75 px-2 py-1 rounded">
                          {formatDate(evenement.Date, true)} - {evenement.Lieu}
                        </p>
                        {user && (
                          <div className="flex items-center">
                            <Link
                              to={{
                                pathname: `/evenement/modify/${evenement.id}`,
                              }}
                              className="mr-2"
                            >
                              <button className="bg-green-500 hover:bg-green-600 text-white font-bold mt-1 py-1 px-2 rounded">
                                Modifier
                              </button>
                            </Link>
                            <button
                              onClick={() => handleDelete(evenement)}
                              className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                            >
                              Supprimer
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </a>
                ) : (
                  <div
                    className="bg-cover bg-center h-80 rounded-lg overflow-hidden transition-opacity duration-300 ease-in-out"
                    style={{
                      backgroundImage: `url(${evenement.pathImageDownloadUrl})`,
                    }}
                  >
                    <div className="absolute inset-0 bg-black opacity-50 transition-opacity duration-300 ease-in-out group-hover:opacity-0"></div>
                    <div className="absolute bottom-0 left-0 p-4">
                      <h3 className="text-lg font-semibold text-white mb-1 bg-black bg-opacity-75 px-2 py-1 rounded">
                        {evenement.Nom}
                      </h3>
                      <p className=" text-white bg-black bg-opacity-75 px-2 py-1 rounded">
                        {formatDate(evenement.Date, true)} - {evenement.Lieu}
                      </p>
                      {user && (
                        <div className="flex items-center">
                          <Link
                            to={{
                              pathname: `/evenement/modify/${evenement.id}`,
                            }}
                            className="mr-2"
                          >
                            <button className="bg-green-500 hover:bg-green-600 text-white font-bold mt-1 py-1 px-2 rounded">
                              Modifier
                            </button>
                          </Link>
                          <button
                            onClick={() => handleDelete(evenement)}
                            className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                          >
                            Supprimer
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
export default PlanningPage;
