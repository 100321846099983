// Fichier : routes.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import JeunessePage from "../Pages/laJeunesse/jeunessePage";
import ComitePage from "../Pages/leComite/comitePage";
import ProtectedRoute from "./ProtectedRoute";
import { Login, Logout, logout } from "../Pages/auth/login";
import PlanningPage from "../Pages/planning/planningPage";
import ContactPage from "../Pages/contact/contactPage";
import NosSponsorsPage from "../Pages/nosSponsors/sponsorsEtDonPage";
import Rjv2025Page from "../Pages/rjv2025/rjv2025Page";
import ErrorPage from "../Pages/error/errorPage";
import ChartePage from "../Pages/charte/chartePage";
import ResponsabilitePage from "../Pages/responsabilite/responsabilitePage";
import {
	CreateMembre,
	UpdateMembre,
} from "../Pages/crud_pages/crud_membre/membreForm";
import {
	CreateEvenement,
	UpdateEvenement,
} from "../Pages/crud_pages/crud_evenement/evenementForm";
import InscriptionPage from "../Pages/inscription/inscriptionPage";
const AppRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<JeunessePage />} />
			<Route path="/comite" element={<ComitePage />} />
			<Route path="/login" element={<Login />} />
			<Route
				path="/logout"
				render={() => {
					Logout(); // Appel de la fonction de déconnexion
					return null; // Rien n'est rendu
				}}
			/>
			<Route path="*" element={<ErrorPage />} />
			<Route path="/planning" element={<PlanningPage />} />
			<Route path="/contact" element={<ContactPage />} />
			<Route path="/dons" element={<NosSponsorsPage />} />
			<Route path="/rjv2025" element={<Rjv2025Page />} />
			<Route path="/charte" element={<ChartePage />} />
			<Route path="/responsabilite" element={<ResponsabilitePage />} />

			<Route
				path="/comite/create"
				element={
					<ProtectedRoute>
						<CreateMembre />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/comite/modify/:membreId"
				element={
					<ProtectedRoute>
						<UpdateMembre />{" "}
					</ProtectedRoute>
				}
			/>
			<Route
				path="/evenement/create"
				element={
					<ProtectedRoute>
						<CreateEvenement />{" "}
					</ProtectedRoute>
				}
			/>
			<Route path="/inscription" element={<InscriptionPage />} />

			<Route
				path="/evenement/modify/:evenementId"
				element={
					<ProtectedRoute>
						<UpdateEvenement />{" "}
					</ProtectedRoute>
				}
			/>
		</Routes>
	);
};

export default AppRoutes;
